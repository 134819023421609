<template>
    <div class="my-profile-privacy container">
        <page-header
            :title="`신분증 및 입금 통장을\n등록해주세요.`"
            titleSize="34px"
            :subtitle="'계약 또는 정산을 위해 꼭 필요한 서류입니다.\n미 등록 시 정산이 누락되거나 늦어질 수 있어요!'"
        />

        <div class="ui-border-line"></div>

        <template v-for="(val, key) in formData">
            <div :key="key">
                <template v-if="val">
                    <div class="image-item" @click="() => openActionModal(key)">
                        <div
                            class="ui-bg-img"
                            :style="{ backgroundImage: val ? `url(${$lib.cdnUrl(val)})` : null }"
                        ></div>
                        <div class="ui-icon-menu">편집</div>
                    </div>
                    <div class="ui-border-line"></div>
                </template>
                <template v-else>
                    <div class="ui-inputbox ui-btn btn-add" @click="() => openActionModal(key, 'post')">
                        <p>{{label(key)}}</p>
                        <icon
                            src="img/common/plus-gray.png"
                            width="2rem"
                            height="11rem"
                            :cover="false"
                        />
                    </div>
                </template>
            </div>
        </template>

        <div class="my-profile-privacy-desc">
            등록한 정보는
            <router-link class="color-lavender text-underline" to="/etc/privacy">개인정보 취급방침</router-link>에 의해 관리됩니다.
            <br />* 신분증(사업자등록증)과 예금주명이 동일해야 합니다.
            <br />* 등록된 정보로 계약 또는 정산 입금됩니다.
            <br />
            <div class="ui-p-indent">
                * 등록 신분증
                <br />- 법인, 개인사업자 : 사업자등록증
                <br />- 개인, 팀 : 주민등록증 또는 운전면허증
                <br />(주민등록번호 뒷자리 포함)
            </div>
        </div>

        <modal
            ref="modal"
            :use-footer="false"
        >
            <ul slot="body">
                <li
                    class="text-center"
                    :class="{ on: modalData.action === 'post' }"
                    @click="() => {
                        modalData.action = 'post'
                        confirmActionModal($refs.modal)
                    }"
                >
                    사진 선택하기
                    <div class="ui-border-line"></div>
                </li>
                <li
                    class="text-center"
                    :class="{ on: modalData.action === 'delete' }"
                    @click="() => {
                        modalData.action = 'delete'
                        confirmActionModal($refs.modal)
                    }"
                >
                    삭제하기
                    <div class="ui-border-line"></div>
                </li>
                <li class="text-center" @click="() => $refs.modal.hide()">취소</li>
            </ul>
        </modal>

        <footer-box :submitText="'등록하기'" :submitCb="() => nextStep()" :submitDisabled="!validate" />
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'
import Modal from '@/components/common/Modal'

export default {
    name: 'MyProfilePrivacy',
    components: {
        PageHeader,
        FooterBox,
        Icon,
        Modal,
    },
    data() {
        const  artistAccountInfo =  this.$store.getters.getArtistAccountInfo
        return {
            formData: {
                checkImgUrl: artistAccountInfo?.checkImgUrl,
                bankImgUrl: artistAccountInfo?.bankImgUrl,
            },
            modalData: {
                key: '',
                action: '',
            },
        }
    },
    computed: {
        label() {
            return key => {
                switch (key) {
                    case 'checkImgUrl':
                        return '신분증 or 사업자등록증 선택'
                    case 'bankImgUrl':
                        return '입금 받을 통장 선택'
                }
            }
        },
        validate() {
            return this.formData.checkImgUrl && this.formData.bankImgUrl
        },
        artistAccountInfo() {
            return this.$store.getters.getArtistAccountInfo
        },
    },
    mounted() {
        this.$root.$on('img-uploader:response', (uid, success, url) => {
            if (this._uid === uid && success) {
                this.injectArtistImgUrl(url)
            }
        })
    },
    methods: {
        nextStep() {
            this.updateArtistAccount();
        },
        openActionModal(key, action) {
            if (!key) return
            this.modalData.key = key
            this.modalData.action = action
            if (action === 'post') {
                this.confirmActionModal(this.$refs.modal)
            } else {
                this.$refs.modal.show()
            }
        },
        confirmActionModal(modal) {
            if (this.modalData.action === 'post') {
                this.$root.$emit('img-uploader:request', this._uid, {
                    cropperOption: {
                        aspectRatio: 805 / 1109,
                    },
                    getCroppedCanvasOption: {
                        maxWidth: 805,
                        maxHeight: 1109,
                    },
                })
            } else if (this.modalData.action === 'delete') {
                this.formData[this.modalData.key] = ''
            }
            modal.hide()
        },
        injectArtistImgUrl(url) {
            this.formData[this.modalData.key] = url
        },
        updateArtistAccount() {

            const req = {
                method: 'post',
                url: `/artist/accountInfo`,
                data: {
                    "bankImgUrl" : this.formData.bankImgUrl,
                    "checkImgUrl" : this.formData.checkImgUrl
                }
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$router.push('/artist/my-profile')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
            },
    },
}
</script>

<style lang="scss" scoped>
.my-profile-privacy {
    .my-profile-privacy-desc {
        font-size: 1.9rem;
        color: #5a5a5a;
        line-height: 1.5em;
        padding: 20px 0;
    }

    .image-item {
        overflow: hidden;
        margin: 0 -30px;
        padding: 0 30px;
        cursor: pointer;
        display: flex;
        .ui-bg-img {
            margin: 2rem 0;
            float: left;
            background-color: #efefef;
            width: 100px;
            height: 100px;
        }
        .ui-icon-menu {
            float: right;
            margin: auto 0 auto auto;
            font-size: 21px;
            color: #5a5a5a;
            width: auto;
        }

        &.sortable-drag {
            background-color: #fefefe;
            box-shadow: 0 0.5rem 2rem 0 rgba($color: #000000, $alpha: 0.2);
        }
    }

    .ui-inputbox {
        &.btn-add {
            margin: 0 -3rem;
            padding: 2rem 3rem;
            background-color: #f4f4f4;
            border-bottom: 1px solid #a9a9a9;
            p {
                padding: 0;
                margin: 0;
                line-height: 7rem;
                font-size: inherit;
                color: #a9a9a9;
                border: 0;
                width: 100%;
            }
            .icon {
                position: absolute;
                top: 0;
                right: 3rem;
            }
        }
    }
}
</style>
