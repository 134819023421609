var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-profile-privacy container" },
    [
      _c("page-header", {
        attrs: {
          title: "신분증 및 입금 통장을\n등록해주세요.",
          titleSize: "34px",
          subtitle:
            "계약 또는 정산을 위해 꼭 필요한 서류입니다.\n미 등록 시 정산이 누락되거나 늦어질 수 있어요!"
        }
      }),
      _c("div", { staticClass: "ui-border-line" }),
      _vm._l(_vm.formData, function(val, key) {
        return [
          _c(
            "div",
            { key: key },
            [
              val
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "image-item",
                        on: {
                          click: function() {
                            return _vm.openActionModal(key)
                          }
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "ui-bg-img",
                          style: {
                            backgroundImage: val
                              ? "url(" + _vm.$lib.cdnUrl(val) + ")"
                              : null
                          }
                        }),
                        _c("div", { staticClass: "ui-icon-menu" }, [
                          _vm._v("편집")
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "ui-border-line" })
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "ui-inputbox ui-btn btn-add",
                        on: {
                          click: function() {
                            return _vm.openActionModal(key, "post")
                          }
                        }
                      },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.label(key)))]),
                        _c("icon", {
                          attrs: {
                            src: "img/common/plus-gray.png",
                            width: "2rem",
                            height: "11rem",
                            cover: false
                          }
                        })
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ]
      }),
      _c(
        "div",
        { staticClass: "my-profile-privacy-desc" },
        [
          _vm._v(" 등록한 정보는 "),
          _c(
            "router-link",
            {
              staticClass: "color-lavender text-underline",
              attrs: { to: "/etc/privacy" }
            },
            [_vm._v("개인정보 취급방침")]
          ),
          _vm._v("에 의해 관리됩니다. "),
          _c("br"),
          _vm._v("* 신분증(사업자등록증)과 예금주명이 동일해야 합니다. "),
          _c("br"),
          _vm._v("* 등록된 정보로 계약 또는 정산 입금됩니다. "),
          _c("br"),
          _vm._m(0)
        ],
        1
      ),
      _c("modal", { ref: "modal", attrs: { "use-footer": false } }, [
        _c("ul", { attrs: { slot: "body" }, slot: "body" }, [
          _c(
            "li",
            {
              staticClass: "text-center",
              class: { on: _vm.modalData.action === "post" },
              on: {
                click: function() {
                  _vm.modalData.action = "post"
                  _vm.confirmActionModal(_vm.$refs.modal)
                }
              }
            },
            [
              _vm._v(" 사진 선택하기 "),
              _c("div", { staticClass: "ui-border-line" })
            ]
          ),
          _c(
            "li",
            {
              staticClass: "text-center",
              class: { on: _vm.modalData.action === "delete" },
              on: {
                click: function() {
                  _vm.modalData.action = "delete"
                  _vm.confirmActionModal(_vm.$refs.modal)
                }
              }
            },
            [_vm._v(" 삭제하기 "), _c("div", { staticClass: "ui-border-line" })]
          ),
          _c(
            "li",
            {
              staticClass: "text-center",
              on: {
                click: function() {
                  return _vm.$refs.modal.hide()
                }
              }
            },
            [_vm._v("취소")]
          )
        ])
      ]),
      _c("footer-box", {
        attrs: {
          submitText: "등록하기",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui-p-indent" }, [
      _vm._v(" * 등록 신분증 "),
      _c("br"),
      _vm._v("- 법인, 개인사업자 : 사업자등록증 "),
      _c("br"),
      _vm._v("- 개인, 팀 : 주민등록증 또는 운전면허증 "),
      _c("br"),
      _vm._v("(주민등록번호 뒷자리 포함) ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }